<template>
  <v-form ref="formRef">
    <v-row>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Rechecks</b>
            <my-badge
              v-if="modelValue?.rechecks?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.rechecks.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(recheckTemplate, 'rechecks')"
            >
              Add Recheck
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.rechecks?.length">
              <v-col cols="12"> Press "Add Recheck" to add new Recheck </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="(item, idx) in modelValue?.rechecks"
                  :key="`title-${idx}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.status"
                        :items="filtersData?.page_statuses"
                        :error-messages="
                          validation[`rechecks.${idx}.page_status_id`]
                        "
                        @blur="
                          validation[`rechecks.${idx}.page_status_id`] = []
                        "
                        hide-details
                        dense
                        outlined
                        label="Status"
                        placeholder="Select status of recheck"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.performer"
                        :items="filtersData?.users"
                        :error-messages="
                          validation[`rechecks.${idx}.performer`]
                        "
                        @blur="validation[`rechecks.${idx}.performer`] = []"
                        item-text="email"
                        item-value="id"
                        hide-details
                        dense
                        outlined
                        label="Performer"
                        placeholder="Select recheck performer"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-text-field
                        v-model="item.button_text"
                        :error-messages="
                          validation[`rechecks.${idx}.button_text`]
                        "
                        @blur="validation[`rechecks.${idx}.button_text`] = []"
                        hide-details
                        dense
                        outlined
                        label="Button text"
                        placeholder="Enter something"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('rechecks', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Approves</b>
            <my-badge
              v-if="modelValue?.approves?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.approves.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(approveTemplate, 'approves')"
            >
              Add Approve
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.approves?.length">
              <v-col cols="12"> Press "Add Approve" to add new Approve </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="(item, idx) in modelValue?.approves"
                  :key="`title-2-${idx}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.status"
                        :items="filtersData?.page_statuses"
                        :error-messages="
                          validation[`approves.${idx}.page_status_id`]
                        "
                        @blur="
                          validation[`approves.${idx}.page_status_id`] = []
                        "
                        hide-details
                        dense
                        outlined
                        label="Status"
                        placeholder="Select status of recheck"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.performer"
                        :items="filtersData?.users"
                        :error-messages="
                          validation[`approves.${idx}.performer`]
                        "
                        @blur="validation[`approves.${idx}.performer`] = []"
                        item-text="email"
                        item-value="id"
                        hide-details
                        dense
                        outlined
                        label="Performer"
                        placeholder="Select recheck performer"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-text-field
                        v-model="item.button_text"
                        :items="[]"
                        :error-messages="
                          validation[`approves.${idx}.button_text`]
                        "
                        @blur="validation[`approves.${idx}.button_text`] = []"
                        hide-details
                        dense
                        outlined
                        label="Button text"
                        placeholder="Enter something"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('approves', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Fields</b>
            <my-badge
              v-if="modelValue?.fields?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.fields.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(fieldTemplate, 'fields')"
            >
              Add Field
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.fields?.length">
              <v-col cols="12"> Press "Add Fields" to add new Field </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="(item, idx) in modelValue?.fields"
                  :key="`title-${idx}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-row dense>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.field"
                            :items="filtersData?.cm_fields"
                            :error-messages="
                              validation[`fields.${idx}.field_id`]
                            "
                            @blur="validation[`fields.${idx}.field_id`] = []"
                            hide-details="auto"
                            dense
                            outlined
                            label="Field"
                            placeholder="Select type of Field"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="item.order"
                            :error-messages="validation[`fields.${idx}.order`]"
                            @blur="validation[`fields.${idx}.order`] = []"
                            hide-details
                            dense
                            outlined
                            label="Order"
                            placeholder="Enter number"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.position"
                            :items="filtersData?.cm_field_positions"
                            :error-messages="
                              validation[`fields.${idx}.position`]
                            "
                            @blur="validation[`fields.${idx}.position`] = []"
                            hide-details
                            dense
                            outlined
                            label="Position"
                            placeholder="Select fields position"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-checkbox
                            v-model="item.is_required"
                            hide-details
                            dense
                            class="ma-1"
                            label="Required"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('fields', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { uid } from "../../../../utils/functions";
import MyBadge from "../../../UI/MyBadge.vue";
import UploadFiltersDataMixin from "../../../../mixins/UploadFiltersDataMixin";

/**
 * @typedef {Object} ModelValue
 * @property {Array<Title>} [titles] - Список заголовков.
 * @property {Array<Recheck>} [rechecks] - Список проверок.
 * @property {Array<Approve>} [approves] - Список утверждений.
 * @property {Array<Field>} [fields] - Список полей.
 */

/**
 * @typedef {Object} Recheck
 * @property {number} id - Уникальный идентификатор проверки.
 * @property {string} status - Статус проверки.
 * @property {number} performer - ID исполнителя.
 * @property {string} button_text - Текст кнопки.
 */

/**
 * @typedef {Object} Approve
 * @property {number} id - Уникальный идентификатор утверждения.
 * @property {string} status - Статус утверждения.
 * @property {number} performer - ID исполнителя.
 * @property {string} button_text - Текст кнопки.
 */

/**
 * @typedef {Object} Field
 * @property {number} id - Уникальный идентификатор поля.
 * @property {string} field - Тип поля.
 * @property {Array<string>} [rules] - Правила, применимые к полю.
 */

/**
 * @type {ModelValue}
 */

export default {
  components: { MyBadge },

  mixins: [UploadFiltersDataMixin],

  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    recheckTemplate: {
      status: null,
      performer: null,
      button_text: null,
    },
    approveTemplate: {
      status: null,
      performer: null,
      button_text: null,
    },
    fieldTemplate: {
      field: null,
      rules: [],
      order: 1,
      position: 2,
    },
    filtersData: {
      page_statuses: [],
      cm_fields: [],
      cm_field_types: [],
      cm_field_positions: [],
      cm_status_types: [],
      users: [],
    },
  }),

  async mounted() {
    this.uploadFiltersData([
      "page_statuses",
      "cm_fields",
      "cm_field_types",
      "cm_field_positions",
      "cm_status_types",
      "users",
    ]);
  },

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    handleRemoveById(target, id) {
      try {
        this.modelValue[target] = this.modelValue[target]?.filter(
          (item) => item.id !== id
        );
      } catch (e) {
        console.error("Cant remove item by id.", e);
      }
    },
    handleAddItemToForm(template, key) {
      if (this.modelValue?.[key] === undefined)
        this.$set(this.modelValue, key, []);

      if (!Array.isArray(this.modelValue[key]))
        return console.error(
          `Cant add new ${key} because form.${key} are not Array.`
        );

      this.modelValue[key].push({ ...template, id: uid() });
    },
  },
};
</script>
