import Service from "@/plugins/service";

export const FieldManagerApiService = () => {
  const ENDPOINTS = {
    FIELDS: "/cm/admin/fields",
    FIELD_DETAILS: (id) => `/cm/admin/fields/${id}`,
    FIELD_EDIT: (id) => `/cm/admin/fields/${id}`,
  };

  const getFields = async () => {
    return Service.get(ENDPOINTS.FIELDS);
  };

  const createField = async (payload) => {
    return Service.post(ENDPOINTS.FIELDS, payload);
  };

  const getFieldDetails = async (id) => {
    return Service.get(ENDPOINTS.FIELD_DETAILS(id));
  };

  const editField = async (id, payload) => {
    return Service.put(
      ENDPOINTS.FIELD_EDIT(id),
      payload,
      true,
      {},
      { disableTossError: true }
    );
  };

  return {
    getFields,
    createField,
    getFieldDetails,
    editField,
  };
};
