<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="px-4 pb-0 pt-4">
      <v-card
        flat
        outlined
        rounded="lg"
        width="100%"
        class="pa-4"
        style="font-size: 16px"
      >
        <div>
          <small>Current status</small>
        </div>
        <div>
          <template v-if="loading">
            <div class="skeleton" style="height: 20px; width: 130px" />
          </template>
          <template v-else>
            <b>{{ formValue?.status?.name }}</b>
            <status-icon small color="primary" />
          </template>
        </div>
        <div class="pt-4 d-flex justify-space-between" style="gap: 0.25rem">
          <div>
            <div class="d-flex" style="gap: 0.25rem">
              <template v-if="loading">
                <div
                  v-for="i in [0, 1]"
                  :key="i"
                  class="skeleton"
                  style="height: 36px; width: 120px"
                />
              </template>
              <template v-else-if="formValue?.recheck_items?.length">
                <v-btn
                  v-for="(button, idx) in formValue?.recheck_items"
                  :key="`recheck-${idx}`"
                  class="text-normal flat-chip flat-chip--warning"
                  @click="handleRecheck(button)"
                  :loading="loadings.updateStatus === button.pivot.button_text"
                  :disabled="
                    loadings.updateStatus !== null &&
                    loadings.updateStatus !== button.pivot.button_text
                  "
                >
                  <v-icon small left>mdi-arrow-left</v-icon>
                  {{ button?.pivot?.button_text }}
                </v-btn>
              </template>
              <template v-else>
                <small style="opacity: 0.5">Without previous steps...</small>
              </template>
            </div>
          </div>
          <div>
            <div class="d-flex" style="gap: 0.25rem">
              <template v-if="loading">
                <div
                  v-for="i in [0, 1, 2]"
                  :key="i"
                  class="skeleton"
                  style="height: 36px; width: 120px"
                />
              </template>
              <template v-else-if="formValue?.approve_items?.length">
                <v-btn
                  v-for="(button, idx) in formValue?.approve_items"
                  :key="`recheck-${idx}`"
                  class="text-normal flat-chip flat-chip--success"
                  @click="handleApprove(button)"
                  :loading="loadings.updateStatus === button.pivot.button_text"
                  :disabled="
                    loadings.updateStatus !== null &&
                    loadings.updateStatus !== button.pivot.button_text
                  "
                >
                  {{ button?.pivot?.button_text }}
                  <v-icon small right>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <small style="opacity: 0.5">Without next steps...</small>
              </template>
            </div>
          </div>
        </div>
      </v-card>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column pa-0"
      style="gap: 0.5rem; font-size: 16px"
    >
      <div class="px-4">
        <div class="mb-1">
          <small>Actions</small>
        </div>
        <div class="d-flex" style="gap: 0.25rem">
          <v-menu
            bottom
            nudge-bottom="32"
            :close-on-content-click="false"
            content-class="my-shadow--e2"
          >
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                class="text-normal"
                @click="handleShowStatusesHistory"
                :loading="loadings.statusesHistory"
              >
                Show statuses history <v-icon small right>mdi-history</v-icon>
              </v-btn>
            </template>
            <v-card flat outlined rounded="lg" width="380">
              <v-card-title class="text-body-2 pb-1 pt-2">
                Statuses history
                <v-spacer />
                <v-btn
                  icon
                  :loading="loadings.statusesHistory"
                  @click="handleShowStatusesHistory(true)"
                >
                  <v-icon small> mdi-refresh </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-title class="pt-0 pb-3">
                <v-text-field
                  v-model="statusesHistorySearch"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  append-icon="mdi-magnify"
                />
              </v-card-title>
              <v-divider />
              <v-card-text
                style="max-height: 320px; overflow-y: scroll"
                class="pa-0"
              >
                <template v-if="loadings.statusesHistory">
                  <div class="px-4 pb-4 pt-2">
                    <div class="skeleton" style="height: 120px; width: 100%" />
                  </div>
                </template>
                <template v-else-if="statusesHistory?.length">
                  <div class="d-flex flex-column">
                    <template
                      v-for="(historyItem, idx) in searchByJson(
                        statusesHistorySearch,
                        statusesHistory
                      )"
                    >
                      <status-history-item
                        v-if="historyItem"
                        :history-item="historyItem"
                        :key="`history-item-${idx}`"
                      />
                      <v-divider
                        v-if="historyItem && idx !== statusesHistory.length - 1"
                        :key="idx"
                      />
                    </template>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu
            bottom
            nudge-bottom="32"
            :close-on-content-click="false"
            content-class="my-shadow--e2"
          >
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                class="text-normal"
                @click="handleShowFieldsHistory"
                :loading="loadings.fieldsHistory"
              >
                Show fields history <v-icon small right>mdi-history</v-icon>
              </v-btn>
            </template>

            <v-card flat outlined rounded="lg" width="380">
              <v-card-title class="text-body-2 pb-1 pt-2">
                Fields history
                <v-spacer />
                <v-btn
                  icon
                  :loading="loadings.fieldsHistory"
                  @click="handleShowFieldsHistory(true)"
                >
                  <v-icon small> mdi-refresh </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-title class="pt-0 pb-3">
                <v-text-field
                  v-model="fieldsHistorySearch"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  append-icon="mdi-magnify"
                />
              </v-card-title>
              <v-divider />
              <v-card-text
                style="max-height: 320px; overflow-y: scroll"
                class="pa-0"
              >
                <template v-if="loadings.fieldsHistory">
                  <div class="px-4 pb-4 pt-2">
                    <div class="skeleton" style="height: 120px; width: 100%" />
                  </div>
                </template>
                <template v-else-if="fieldsHistory?.length">
                  <div class="d-flex flex-column">
                    <template
                      v-for="(historyItem, idx) in searchByJson(
                        fieldsHistorySearch,
                        fieldsHistory
                      )"
                    >
                      <field-history-item
                        v-if="historyItem"
                        :history-item="historyItem"
                        :key="`history-item-${idx}`"
                      />
                      <v-divider
                        v-if="historyItem && idx !== fieldsHistory.length - 1"
                        :key="idx"
                      />
                    </template>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div class="px-4">
        <v-row dense>
          <v-col cols="4">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 pb-2">
                Completed data
                <v-tooltip bottom open-delay="300">
                  <template #activator="{ on }">
                    <v-icon v-on="on" small right
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  Data entered in previous steps
                </v-tooltip>
              </v-card-title>
              <v-card-text style="font-size: 16px">
                <template v-if="loading">
                  <div class="skeleton" style="height: 120px; width: 100%" />
                </template>
                <template v-else-if="formValue?.info_fields?.length">
                  <v-row dense>
                    <v-col
                      v-for="(field, idx) in formValue.info_fields"
                      cols="12"
                      :key="`field-${idx}`"
                    >
                      <element-control-info-field-factory
                        :field="field"
                        :details="formValue?.fields"
                        v-model="field.value"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <small> There are info fields... </small>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2">
                Fill the step form
              </v-card-title>
              <v-card-text style="font-size: 16px">
                <template v-if="loading">
                  <div class="skeleton" style="height: 120px; width: 100%" />
                </template>
                <template v-else-if="formValue?.content_fields?.length">
                  <v-row>
                    <v-col
                      v-for="(field, idx) in formValue.content_fields"
                      cols="12"
                      :key="`field-${idx}`"
                    >
                      <element-control-field-factory
                        :field="field"
                        v-model="field.value"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row>
                    <v-col cols="12">
                      <small>
                        There are no fields to fill in at this step...
                      </small>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions style="background: var(--card-darken-body-color)">
      <v-spacer />
      <v-btn class="px-6 text-normal" large @click="$emit('closeDialog')"
        >Close</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import ElementControlFieldFactory from "./ElementControlFieldFactory.vue";
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";
import StatusHistoryItem from "./StatusHistoryItem.vue";
import FieldHistoryItem from "./FiedHistoryItem.vue";
import StatusIcon from "@/components/SiteWorkplace/SiteWorksheet/components/StatusIcon.vue";
import ElementControlInfoFieldFactory from "@/components/SiteWorkplace/SiteWorksheet/components/ElementControlInfoFieldFactory.vue";
import { CONTENT_FIELD_TYPES } from "@/components/SiteWorkplace/SiteWorksheet/defaults";

export default {
  components: {
    ElementControlInfoFieldFactory,
    StatusIcon,
    StatusHistoryItem,
    ElementControlFieldFactory,
    FieldHistoryItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["statusSuccessfullyUpdated"],

  data: () => ({
    loadings: {
      updateStatus: null,
      fieldsHistory: null,
      statusesHistory: null,
    },
    statusesHistorySearch: null,
    fieldsHistorySearch: null,
    isStatusesHistoryDialogVisible: false,
    statusesHistory: null,
    isFieldsHistoryDialogVisible: false,
    fieldsHistory: null,
  }),

  computed: {
    formValue: {
      get() {
        return this.form;
      },
      set(value) {
        this.$emit("update:form", value);
      },
    },
  },

  methods: {
    searchByJson(searchQuery, items) {
      if (!searchQuery) return items;

      return (
        items?.filter((item) => {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(String(searchQuery).toLowerCase());
        }) || []
      );
    },
    async handleShowStatusesHistory(forceUpload = false) {
      if (forceUpload !== true) {
        if (this.statusesHistory?.length) return;
      }

      try {
        this.loadings.statusesHistory = true;

        const { data } = await SiteWorkSheetApiService().getStatusesHistory(
          this.form._ctx.url_id
        );

        this.statusesHistory = data;
      } catch (e) {
        console.error("Error while loading statuses history.", e);
      } finally {
        this.loadings.statusesHistory = false;
      }
    },
    async handleShowFieldsHistory(forceUpload = false) {
      if (forceUpload !== true) {
        if (this.fieldsHistory?.length) return;
      }

      try {
        this.loadings.fieldsHistory = true;

        const { data } = await SiteWorkSheetApiService().getFieldsHistory(
          this.form._ctx.url_id
        );

        this.fieldsHistory = data;
      } catch (e) {
        console.error("Error while loading fields history.", e);
      } finally {
        this.loadings.fieldsHistory = false;
      }
    },
    getValueFromContentField(fieldData) {
      if (fieldData?.type === CONTENT_FIELD_TYPES.PRICE) {
        try {
          const { currency, value } = fieldData;

          return {
            currency,
            value,
          };
        } catch (e) {
          console.error(
            "Trying to get currency and value values from fieldData.",
            e
          );
        }
      }

      return fieldData.value;
    },
    getPayload(buttonData) {
      const fields = {};

      this.formValue.content_fields.forEach((field) => {
        fields[field.id] = this.getValueFromContentField(field);
      });

      return {
        fields,
        status: buttonData.id,
      };
    },
    async handleRecheck(buttonData) {
      try {
        this.loadings.updateStatus = buttonData.pivot.button_text;
        const { data } = await SiteWorkSheetApiService().updateStatus(
          this.form._ctx.url_id,
          { ...this.getPayload(buttonData), type: "recheck" }
        );

        if (data.status) this.$emit("statusSuccessfullyUpdated");
      } catch (e) {
        console.error("Error while rechecking status.", e);
      } finally {
        this.loadings.updateStatus = null;
      }
    },
    async handleApprove(buttonData) {
      try {
        this.loadings.updateStatus = buttonData.pivot.button_text;
        const { data } = await SiteWorkSheetApiService().updateStatus(
          this.form._ctx.url_id,
          { ...this.getPayload(buttonData), type: "approve" }
        );

        if (data.status) this.$emit("statusSuccessfullyUpdated");
      } catch (e) {
        console.error("Error while approving status.", e);
      } finally {
        this.loadings.updateStatus = null;
      }
    },
  },
};
</script>
