import Service from "../../../../plugins/service";

export const SiteWorkSheetApiService = () => {
  const ENDPOINTS = {
    WORKSHEET_DETAILS: (id) => `/cm/worksheet/${id}`,
    STATUSES_HISTORY: (id) => `/cm/worksheet/${id}/history-statuses`,
    FIELDS_HISTORY: (id) => `/cm/worksheet/${id}/history-fields`,
  };

  const getWorksheetDetailsById = (urlId) => {
    return Service.get(ENDPOINTS.WORKSHEET_DETAILS(urlId));
  };

  const updateStatus = (urlId, payload) => {
    return Service.put(ENDPOINTS.WORKSHEET_DETAILS(urlId), payload);
  };

  const getStatusesHistory = (urlId) => {
    return Service.get(ENDPOINTS.STATUSES_HISTORY(urlId));
  };

  const getFieldsHistory = (urlId) => {
    return Service.get(ENDPOINTS.FIELDS_HISTORY(urlId));
  };

  return {
    getWorksheetDetailsById,
    updateStatus,
    getFieldsHistory,
    getStatusesHistory,
  };
};
