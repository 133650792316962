<template>
  <div>
    <template v-if="field.type === TYPES.EMAIL">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            {{ getDetailsAboutFieldById(field.id)?.value }}
          </template>
          <template v-else>
            <span style="opacity: 0.5"> No value... </span>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="field.type === TYPES.PRICE">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            {{ getDetailsAboutFieldById(field.id)?.value?.value
            }}{{ getDetailsAboutFieldById(field.id)?.additional?.symbol }}
          </template>
          <template v-else>
            <span style="opacity: 0.5"> No value... </span>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="field.type === TYPES.USERS">
      <small>
        {{ field.name }}
      </small>
      <div class="d-flex text-body-2" style="gap: 0.25rem">
        <v-avatar size="38">
          <v-img :src="getDetailsAboutFieldById(field.id)?.additional?.photo" />
        </v-avatar>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.additional?.name">
            <div>
              <b>{{ getDetailsAboutFieldById(field.id)?.additional?.email }}</b>
            </div>
            <div>
              {{ getDetailsAboutFieldById(field.id)?.additional?.name }}
            </div>
          </template>

          <template v-else>
            <span style="opacity: 0.5"> No value... </span>
          </template>
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            {{ getDetailsAboutFieldById(field.id)?.value }}
          </template>
          <template v-else>
            <span style="opacity: 0.5"> No value... </span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CONTENT_FIELD_TYPES } from "@/components/SiteWorkplace/SiteWorksheet/defaults";

export default {
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    details: {
      type: Object,
      default: () => ({}),
    },
    value: {},
  },

  inject: ["filtersData", "filtersDataPendingKeys"],

  data: () => ({
    TYPES: CONTENT_FIELD_TYPES,
  }),

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    getDetailsAboutFieldById(fieldId) {
      return this.details[fieldId];
    },
  },
};
</script>
