<template>
  <div>
    <template v-if="field.type === TYPES.EMAIL">
      <v-autocomplete
        v-model="modelValue"
        :items="field?.items || []"
        :label="field.name"
        clearable
        item-text="email"
        item-value="id"
        hide-details
        dense
        outlined
        placeholder="Select user"
        prepend-inner-icon="mdi-account"
      />
    </template>
    <template v-else-if="field.type === TYPES.URL">
      <v-text-field
        v-model="modelValue"
        :label="field.name"
        clearable
        hide-details
        dense
        outlined
        placeholder="Enter URL"
        prepend-inner-icon="mdi-link"
      />
    </template>
    <template v-else-if="field.type === TYPES.USERS">
      <v-autocomplete
        v-model="modelValue"
        :label="field.name"
        :items="field?.items || []"
        clearable
        hide-details
        dense
        outlined
        placeholder="Select user"
        prepend-inner-icon="mdi-account"
      />
    </template>
    <template v-else-if="field.type === TYPES.TEXT_FIELD">
      <v-text-field
        v-model="modelValue"
        :label="field.name"
        clearable
        hide-details
        dense
        outlined
        placeholder="Enter text"
      />
    </template>
    <template v-else-if="field.type === TYPES.LANGUAGE">
      <v-autocomplete
        v-model="modelValue"
        :label="field.name"
        :items="field?.items || []"
        clearable
        hide-details
        dense
        outlined
        placeholder="Select language"
        prepend-inner-icon="mdi-translate"
      />
    </template>
    <template v-else-if="field.type === TYPES.NUMBER">
      <v-text-field
        v-model="modelValue"
        :label="field.name"
        clearable
        hide-details
        dense
        outlined
        placeholder="Enter number"
      />
    </template>
    <template v-else-if="field.type === TYPES.PRICE">
      <v-row dense>
        <v-col v-if="field?.name" cols="12" class="text-body-2 pb-0">
          {{ field.name }}
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="field['value']"
            clearable
            hide-details
            dense
            outlined
            placeholder="Enter price"
          />
        </v-col>
        <v-col cols="5">
          <v-autocomplete
            v-model="field['currency']"
            :items="field?.items || []"
            item-text="iso"
            item-value="id"
            clearable
            hide-details
            dense
            outlined
            placeholder="Select currency"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.TEXTAREA">
      <v-textarea
        v-model="modelValue"
        :label="field.name"
        clearable
        hide-details
        dense
        outlined
        placeholder="Enter text"
      />
    </template>
    <template v-else>
      <div style="opacity: 0.5">
        Field type is not supported. ({{ field.type }})
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    value: {},
  },

  inject: ["filtersData", "filtersDataPendingKeys"],

  data: () => ({
    TYPES: {
      EMAIL: 1,
      USERS: 2,
      URL: 3,
      TEXT_FIELD: 4,
      TEXTAREA: 5,
      LANGUAGE: 6,
      PRICE: 7,
      NUMBER: 8,
    },
  }),

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
