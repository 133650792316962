export const CONTENT_FIELD_TYPES = {
  EMAIL: 1,
  USERS: 2,
  URL: 3,
  TEXT_FIELD: 4,
  TEXTAREA: 5,
  LANGUAGE: 6,
  PRICE: 7,
  NUMBER: 8,
};
