<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="920"
    content-class="my-shadow--e2"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Edit status "<u>{{ formData?._ctx?.name }}</u
        >"
        <v-icon small right>mdi-pencil</v-icon>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <status-form v-model="formData" :validation="validationResult" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          :loading="loadings.confirm"
          @click="handleConfirm"
        >
          Save status changes <v-icon small right>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import Notification from "../../../../mixins/Notification";
import StatusForm from "./StatusForm.vue";
import { StatusManagerApiService } from "../services/StatusManagerApiService";
import ApiErrorHandlingMixin from "../../../../mixins/ApiErrorHandlingMixin";

export default {
  components: { StatusForm },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [Dialog, ApiErrorHandlingMixin, Notification],

  watch: {
    data: {
      handler(value) {
        if (value) this.formData = value;
      },
      deep: true,
      immediate: true,
    },
  },

  data: () => ({
    formData: {},
    loadings: {
      confirm: false,
    },
  }),

  methods: {
    resetForm() {
      this.$set(this, "formData", {});
    },
    getEditStatusPayload(formData) {
      return {
        approve_statuses:
          formData.approves?.map((approve) => ({
            page_status_id: approve.status,
            performer: approve.performer,
            button_text: approve.button_text,
          })) || [],
        recheck_statuses:
          formData.rechecks?.map((recheck) => ({
            page_status_id: recheck.status,
            performer: recheck.performer,
            button_text: recheck.button_text,
          })) || [],
        fields:
          formData.fields?.map((field) => ({
            field_id: field.field,
            order: field.order,
            is_required: field.is_required,
            position: field.position,
          })) || [],
      };
    },
    async handleConfirm() {
      console.log(this.formData);

      try {
        this.loadings.confirm = true;

        const payload = this.getEditStatusPayload(this.formData);

        console.log("edit payload:", payload);

        await StatusManagerApiService().editStatus(this.data.id, payload);
        this.resetForm();
        this.dialog = false;
        this.mSuccess({
          title: "Done",
          text: "Step updated successfully",
        });
      } catch (e) {
        console.error("Error while editing status.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.confirm = false;
      }
    },
  },
};
</script>
