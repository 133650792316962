<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Status manager"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            {
              text: 'Site workplace',
              to: { name: 'site-workplace.dashboard' },
            },
            { text: 'Status manager', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            <b>Statuses</b>
            <v-spacer />
            <v-btn
              v-if="false"
              class="text-normal"
              color="primary"
              @click="handleCreateStatus"
            >
              Create new status <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-title class="pa-3">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 pt-0 pb-2">
            <v-data-table
              :search="search"
              :items="tableData.items"
              :headers="[...tableData.headers, { text: '', value: '_actions' }]"
            >
              <template #[`item._actions`]="{ item }">
                <div class="d-flex justify-end">
                  <v-btn
                    :loading="loadings.edit === item.id"
                    :disabled="loadings.edit && loadings.edit !== item.id"
                    class="text-normal"
                    @click="handleEditStatus(item)"
                  >
                    Edit <v-icon small right>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-status-dialog v-model="isCreateStatusDialogVisible" />
    <edit-status-dialog
      v-model="isEditStatusDialogVisible"
      :data="editStatusData"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import CreateStatusDialog from "./components/CreateStatusDialog.vue";
import EditStatusDialog from "./components/EditStatusDialog.vue";
import { StatusManagerApiService } from "./services/StatusManagerApiService";
import { uid } from "@/utils/functions";

export default {
  components: { EditStatusDialog, CreateStatusDialog, PageHeader },

  data: () => ({
    isCreateStatusDialogVisible: false,
    isEditStatusDialogVisible: false,
    editStatusData: null,
    search: null,
    tableData: {
      items: [],
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Priority",
          value: "priority",
        },
      ],
    },
    loadings: {
      statuses: false,
      edit: null,
    },
    mockEditData: {
      titles: [
        {
          from: null,
          to: null,
          title: "Test title 1",
          id: "id66e1d405094c5",
        },
      ],
      fields: [
        {
          status: null,
          performer: null,
          id: "id0aec9854d564d",
        },
      ],
      approves: [
        {
          status: null,
          performer: null,
          button_text: null,
          id: "id5be8e86fcb247",
        },
        {
          status: null,
          performer: null,
          button_text: null,
          id: "id52790e06faad5",
        },
      ],
      rechecks: [
        {
          status: null,
          performer: null,
          button_text: null,
          id: "id5be8e86fcb247",
        },
        {
          status: null,
          performer: null,
          button_text: null,
          id: "id52790e06faad5",
        },
      ],
    },
  }),

  mounted() {
    this.initializeStatuses();
  },

  methods: {
    async initializeStatuses() {
      try {
        this.loadings.statuses = true;

        const { data } = await StatusManagerApiService().getStatuses();

        this.tableData.items = data;
      } catch (e) {
        console.error("Error while loading statuses list.", e);
      } finally {
        this.loadings.statuses = false;
      }
    },
    handleCreateStatus() {
      this.isCreateStatusDialogVisible = true;
    },
    statusFormAdapter(data) {
      return {
        rechecks:
          data.recheck_items?.map((item) => ({
            status: item?.pivot?.sibling_id,
            performer: item?.pivot?.performer,
            button_text: item?.pivot?.button_text,
            id: uid(),
          })) || [],
        approves:
          data.approve_items?.map((item) => ({
            status: item?.pivot?.sibling_id,
            performer: item?.pivot?.performer,
            button_text: item?.pivot?.button_text,
            id: uid(),
          })) || [],
        fields:
          data.fields?.map((item) => ({
            field: item?.pivot?.field_id,
            order: item?.pivot?.order,
            is_required: !!item?.pivot?.is_required,
            position: item?.pivot?.position,
            id: uid(),
          })) || [],
      };
    },
    async handleEditStatus(statusData) {
      console.log(statusData);
      try {
        this.loadings.edit = statusData.id;

        const { data } = await StatusManagerApiService().getStatusDetails(
          statusData.id
        );

        this.isEditStatusDialogVisible = true;
        this.editStatusData = {
          id: statusData.id,
          ...this.statusFormAdapter(data),
          _ctx: statusData,
        };
      } catch (e) {
        console.error("Error while loading status details.", e);
      } finally {
        this.loadings.edit = null;
        // TODO Temporary ( Remove after )
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
