<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="620"
    content-class="my-shadow--e2"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Edit field <v-icon small right>mdi-pencil</v-icon>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.name"
              hide-details
              dense
              outlined
              :error-messages="validationResult['name']"
              @blur="validationResult['name'] = []"
              label="Title name"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.type"
              hide-details
              dense
              outlined
              label="Type"
              placeholder="Type to search"
              :error-messages="validationResult['type']"
              @blur="validationResult['type'] = []"
              :items="filtersData.cm_field_types"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="text-normal px-6" @click="dialog = false"
          >Close</v-btn
        >
        <v-btn
          large
          class="text-normal px-6"
          @click="handleCreate"
          color="success"
          :loading="loadings.edit"
          >Save field changes <v-icon small right>mdi-pencil</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UploadFiltersDataMixin from "../../../../mixins/UploadFiltersDataMixin";
import { FieldManagerApiService } from "../services/FieldManagerApiService";
import Dialog from "../../../../mixins/Dialog";
import Notification from "../../../../mixins/Notification";
import apiErrorHandlingMixin from "../../../../mixins/ApiErrorHandlingMixin";

export default {
  mixins: [Dialog, UploadFiltersDataMixin, apiErrorHandlingMixin, Notification],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    filtersData: {
      cm_field_types: [],
    },
    loadings: {
      edit: false,
    },
    form: {
      name: null,
      type: null,
    },
  }),

  mounted() {
    this.uploadFiltersData(["cm_field_types"]);
  },

  watch: {
    data: {
      handler(value) {
        this.form = { ...value };
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    resetForm() {
      this.form = {
        name: null,
        type: null,
      };
    },
    async handleCreate() {
      try {
        this.loadings.edit = true;
        await FieldManagerApiService().editField(this.data.id, this.form);
        this.resetForm();
        this.mSuccess({
          title: "Done",
          text: "Field successfully edited.",
        });
        this.$emit("edited");
        this.dialog = false;
      } catch (e) {
        console.error("Error while creating field", e);
        this.handleApiError(e);
      } finally {
        this.loadings.edit = false;
      }
    },
  },
};
</script>
