<template>
  <div>
    <v-btn fixed dark bottom right large color="pink" @click="dialog = true">
      Upload keys<v-icon right>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      content-class="remove-dialog-shadow"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          <v-progress-linear
            v-model="import_progress"
            absolute
            top
            color="info"
          ></v-progress-linear>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-6">
            <v-row>
              <v-col cols="12" md="4">
                <v-alert text icon="mdi-bug" type="error">
                  Errors: <strong>{{ stat.errors }}</strong>
                </v-alert>
              </v-col>
              <v-col cols="12" md="4">
                <v-alert text icon="mdi-check" type="success">
                  Success: <strong>{{ stat.success }}</strong>
                </v-alert>
              </v-col>
              <v-col cols="12" md="4">
                <v-alert text icon="mdi-format-list-bulleted" type="info">
                  Phrases: <strong>{{ stat.keys }}</strong>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="errors.length > 0">
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      Errors
                      <template v-slot:actions>
                        <v-icon color="error"> mdi-bug </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-alert
                        v-for="item in errors"
                        :key="item.line"
                        text
                        type="error"
                      >
                        Line: {{ item.line }}<br />
                        Error: {{ item.error }}
                      </v-alert>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-subheader class="pa-0">
                    Select the columns to import in the order in which you load
                    the data
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        phrase; target_url; category; priority; custom_value;
                        target_serp_top
                      </span>
                    </v-tooltip>
                  </v-subheader>
                  <v-text-field
                    v-model="form.columns"
                    :rules="rules.columns"
                    label="Columns"
                    required
                    solo
                  ></v-text-field>
                  <v-subheader class="pa-0">
                    Insert data to import (no headers)
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        keywords 1; https://site.com/url1; category 1; F; 100;
                        20<br />
                        keywords 2; https://site.com/url1; category 2; S; 500;
                        10
                      </span>
                    </v-tooltip>
                  </v-subheader>
                  <v-textarea
                    v-model="form.keys"
                    :rules="rules.keys"
                    label="Keywords"
                    required
                    solo
                    no-resize
                  ></v-textarea>
                  <v-subheader class="pa-0"
                    >What to do with existing keys</v-subheader
                  >
                  <v-radio-group v-model="form.duplicates">
                    <v-radio :key="0" label="Skip" value="skip"></v-radio>
                    <v-radio :key="1" label="Replace" value="replace"></v-radio>
                  </v-radio-group>
                </v-form>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      Allowed columns
                      <template v-slot:actions>
                        <v-icon color="info"> mdi-alert-circle-outline </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div><b>phrase *</b> - keyword</div>
                      <div>
                        <b>target_url *</b> - url on which the phrase should be
                      </div>
                      <div><b>category *</b> - category for phrases</div>
                      <div>
                        <b>lang</b> - language for phrases (iso format: fr, en,
                        it)
                      </div>
                      <div>
                        <b>priority</b> - a text box with the following values:
                        <div class="pl-3">
                          <b class="font-w700">F - f</b>ocus (main project
                          phases),<br />
                          <b class="font-w700">S - s</b>econdary (less phrases
                          for the project),<br />
                          <b class="font-w700">T - t</b>extOnly (phrases that
                          should only appear in the text).
                        </div>
                      </div>
                      <div>
                        <b>custom_value</b> - (int) - integer values, for
                        entering additional data in manual mode, for example, by
                        impressions from the search console
                      </div>
                      <div>
                        <b>target_serp_top</b> - desired top at the moment 1, 3,
                        5,10, 30, 50, 100.
                      </div>
                      <br />
                      <h3>Freq Phrase</h3>
                      <div><b>phrase *</b> - keyword</div>
                      <div>
                        <b>target_url *</b> - url on which the phrase should be
                      </div>
                      <div><b>category *</b> - category for phrases</div>
                      <div><b>freq *</b> - 1000</div>
                      <div><b>country *</b> - US (iso_alpha_2)</div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            text
            @click="importData"
            :disabled="status_importing"
            :loading="status_importing"
          >
            Start Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadKeys",
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: true,
      form: {
        columns: "phrase; target_url; category;",
        keys: "",
        duplicates: "skip",
      },
      rules: {
        columns: [(v) => !!v || "Columns is required"],
        keys: [(v) => !!v || "Keywords is required"],
      },
      dialog: false,
      status_importing: false,
      import_progress: 0,
      stat: {
        errors: 0,
        success: 0,
        keys: 0,
      },
      errors: [],
    };
  },
  methods: {
    importData() {
      let self = this;

      if (!self.$refs.form.validate()) {
        return;
      }

      let keys = self.form.keys
        .split("\n")
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      self.status_importing = true;

      self.import_progress = 0;
      self.stat.success = 0;
      self.stat.errors = 0;
      self.stat.keys = keys.length;

      self.importDataPieces(keys, 0);
    },
    importDataPieces(keys, index) {
      let self = this;

      if (keys[index] === undefined) {
        self.import_progress = 100;
        self.status_importing = false;
        return;
      }

      let pack = [];

      for (let i = index; i < index + 500; i++) {
        if (keys[i] === undefined) {
          break;
        }

        pack.push(keys[i]);
      }

      let columns = self.form.columns;

      let url = `${self.$store.state.server_url}/keyword-control/${self.idProject}/upload-keys`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        keys: pack,
        columns: columns,
      };

      axios.post(url, data, config).then(function (response) {
        self.import_progress = Math.round(((index + 500) * 100) / keys.length);

        if (response.data.success) {
          let indexNext = index + pack.length;

          self.stat.success += pack.length;

          self.importDataPieces(keys, indexNext);
        } else {
          self.stat.errors = response.data.errors.length;
          self.errors = response.data.errors;
          self.status_importing = false;
        }
      });
    },
  },
  computed: {
    idProject() {
      return this.filters.project;
    },
  },
};
</script>

<style scoped></style>
