import Service from "@/plugins/service";

export const StatusManagerApiService = () => {
  const ENDPOINTS = {
    STATUSES: "/cm/admin/statuses",
    STATUS_DETAILS: (id) => `/cm/admin/statuses/${id}`,
    STATUS_EDIT: (id) => `/cm/admin/statuses/${id}`,
  };

  const getStatuses = async () => {
    return Service.get(ENDPOINTS.STATUSES);
  };

  const getStatusDetails = async (id) => {
    return Service.get(ENDPOINTS.STATUS_DETAILS(id));
  };

  const editStatus = async (id, payload) => {
    return Service.put(
      ENDPOINTS.STATUS_EDIT(id),
      payload,
      true,
      {},
      { disableTossError: true }
    );
  };

  return {
    getStatuses,
    getStatusDetails,
    editStatus,
  };
};
